@use 'styles/breakpoints' as *;

.pill {
  display: inline-flex;
  gap: 0.25rem;
  align-items: center;
  padding: 0.3125rem 0.625rem;

  @include desktop {
    padding: 0.625rem 1rem;
  }

  border-radius: 62.4375rem;
  border: 0.125rem solid rgb(from var(--clr-dark-gray) r g b / 50%);

  &.inverted {
    border-color: rgb(from var(--clr-white) r g b / 30%);
  }
}

.icon {
  display: none;
  width: 1.1325rem;
  height: 1.1325rem;

  &.inverted {
    color: var(--clr-white);
  }

  @include desktop {
    display: block;
    width: 1.6875rem;
    height: 1.6875rem;
  }
}

.label {
  padding: 0 0.25rem;
}
