@use 'styles/breakpoints' as *;

.pagination {
  --items-margin: 2.5rem;
  --pagecount-size: 1.125rem;

  display: flex;
  align-items: center;
  justify-content: center;

  @include desktop {
    --items-margin: 4rem;
    --pagecount-size: 1.25rem;
  }
}

.pageCount {
  margin: 0 var(--items-margin);
  font-size: var(--pagecount-size);
  font-weight: 700;
  color: rgba(30, 30, 30, 0.4);

  @include desktop {
    display: none;
  }
}

.currentPage {
  color: var(--clr-dark-gray);
}

.itemList {
  margin: 0 var(--items-margin);
  display: none;
  gap: 1rem;

  @include desktop {
    display: flex;
  }
}

.ellipsis {
  pointer-events: none;
}
