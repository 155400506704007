.personTile {
  position: relative;
  width: var(--people-carousel-width);
  aspect-ratio: var(--people-carousel-ratio);
  padding: var(--people-carousel-padding);
  display: flex;
  user-select: none;
}

.image {
  z-index: -1;

  img {
    object-fit: cover;
  }

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 13.54%,
      rgba(0, 0, 0, 0.5) 100%
    );
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--people-carousel-v-gap);
  color: var(--clr-white);
  margin-top: auto;
}

.quote {
  font-weight: 500;
  font-size: var(--p-reg-size);
  line-height: 1.5;
}

.person {
  display: flex;
  gap: var(--people-carousel-name-gap);
}

.name {
  color: var(--clr-white);
  font-size: var(--p-reg-size);
  font-weight: 700;
}

.function {
  color: rgba(255, 255, 255, 0.6);
  font-weight: 700;
}
