@use 'styles/breakpoints' as *;

.iconButton {
  font-family: var(--inter-font);
  font-size: var(--btn-font-size) !important;
  line-height: 1;
  font-weight: 700;
  justify-content: center;
  position: relative;
  color: var(--text-color);
  --btn-morph-size-enlarged: 1;
  padding: 1rem !important;

  @include desktop {
    padding: 1.25rem !important;
  }

  .icon {
    z-index: 0;
    width: 2rem;
    height: 2rem;

    @include desktop {
      width: 2.5rem;
      height: 2.5rem;
    }
  }

  &.primary {
    --bg-color: var(--clr-primary);

    &.neutral {
      --text-color: var(--clr-white);
      --hover-text-color: var(--clr-white);
    }

    &.inverted {
      --text-color: var(--clr-dark-gray);
      --hover-text-color: var(--clr-dark-gray);
    }

    &.ghost {
      --text-color: var(--clr-primary);
    }
  }

  &.secondary {
    &.neutral {
      --bg-color: var(--clr-dark-gray);
      --text-color: var(--clr-white);
      --hover-text-color: var(--clr-white);

      &.ghost {
        --text-color: var(--clr-dark-gray);
      }
    }

    &.inverted {
      --bg-color: var(--clr-white);
      --text-color: var(--clr-dark-gray);
      --hover-text-color: var(--clr-dark-gray);

      &.ghost {
        --text-color: var(--clr-white);
      }
    }
  }

  &.tertiary {
    --bg-color: transparent;
    --text-color: var(--clr-dark-gray);
    --border-color: var(--clr-dark-gray);

    &.neutral {
      --text-color: var(--clr-dark-gray);
      --border-color: var(--clr-dark-gray);
      --hover-text-color: var(--clr-dark-gray);
    }

    &.inverted {
      --text-color: var(--clr-white);
      --border-color: var(--clr-white);
      --hover-text-color: var(--clr-white);
    }
  }

  &.muted {
    --bg-color: transparent;

    &:hover {
      --bg-color: var(--clr-neutral-subtle);
    }

    &.neutral {
      --text-color: var(--clr-dark-gray);
      --hover-text-color: var(--clr-dark-gray);
    }

    &.inverted {
      --text-color: var(--clr-white);
      --hover-text-color: var(--clr-white);
    }
  }

  &.primary,
  &.secondary,
  &.tertiary,
  &.muted {
    &::before,
    &::after {
      content: '';
      box-sizing: border-box;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 50%;
      top: 50%;
    }

    &::before {
      z-index: -2;
      background-color: transparent;
      transform: translate(-50%, -50%);
    }

    &:not(.ghost) {
      --btn-morph-size-default: 100%;
      --btn-morph-size-enlarged: 1.1;
    }

    &::after {
      z-index: -1;
      --morph-size: var(--btn-morph-size-default);
      transform: translate(-50%, -50%) scale(var(--morph-size));
      transition: all var(--transition-duration) ease;
    }

    &:not(.ghost)::after {
      background-color: var(--bg-color);
      border: var(--btn-border-size) solid var(--border-color);
    }

    &:not(.ghost) {
      --btn-morph-size-default: 100%;
    }
  }

  &:hover {
    color: var(--hover-text-color);

    &::after {
      --morph-size: var(--btn-morph-size-enlarged);
      background-color: var(--bg-color);
      transform: translate(-50%, -50%) scale(var(--morph-size));
      border: var(--btn-border-size) solid var(--border-color);
    }
  }

  &.withoutPadding {
    padding: 0;

    &::after,
    &::before {
      width: calc(100% + 2rem);
      height: calc(100% + 2rem);

      @include desktop {
        width: calc(100% + 2.5rem);
        height: calc(100% + 2.5rem);
      }
    }
  }

  &.circle {
    &::before,
    &::after {
      border-radius: 50%;
    }
  }
}
