@use 'styles/breakpoints' as *;

$font-weight-medium: 500;
$font-weight-bold: 700;

h1.typography,
h2.typography,
h3.typography,
h4.typography,
h5.typography {
  text-wrap: balance;
  hyphens: auto;
}

p.typography,
span.typography {
  font-family: var(--inter-font);
  text-wrap: pretty;
}

.typography {
  &.font-neue-plak {
    font-family: var(--neue-plak-font);
    text-transform: uppercase;
  }

  &.font-inter {
    font-family: var(--inter-font);
  }

  &.primary {
    color: var(--clr-primary);
  }

  &.secondary {
    color: var(--clr-dark-gray);
  }

  &.white {
    color: var(--clr-white);
  }

  &.muted {
    color: var(--clr-dark-gray-gray-muted);
  }

  &.error {
    color: var(--clr-error);
  }

  font-weight: $font-weight-medium;
  line-height: 1.5rem;

  &.input,
  &.input-label {
    font-size: 1rem;
    line-height: 1.5;

    @include desktop {
      font-size: 1.5rem;
    }
  }

  &.input-support {
    font-size: 0.75rem;
    line-height: 0.975rem;

    @include desktop {
      font-size: 1.125rem;
      line-height: 1.4625rem;
    }
  }

  &.link {
    text-decoration: underline;
    cursor: pointer;
    font-size: 1rem;
    line-height: 1.8rem;

    @include desktop {
      font-size: 1.5rem;
      line-height: 2.7rem;
    }
  }

  &.lead {
    font-size: 1.25rem;
    line-height: 2.25rem;

    @include desktop {
      font-size: 1.75rem;
      line-height: 3.15rem;
    }
  }

  &.heading2-sub {
    font-size: 2rem;
    line-height: 1.8rem;
    color: var(--clr-primary);

    @include desktop {
      font-size: 4rem;
      line-height: 3.6rem;
    }
  }

  &.heading1-display {
    font-size: 5.5rem;
    line-height: 4.4rem;

    @include desktop {
      font-size: 11.75rem;
      line-height: 9.4rem;
    }
  }

  &.heading1-display-cases {
    font-size: 5.5rem;
    line-height: 4.4rem;

    @include desktop {
      font-size: 10rem;
      line-height: 8rem;
    }
  }

  &.heading1-sub {
    font-size: 2.5rem;
    line-height: 2rem;

    @include desktop {
      font-size: 3.5rem;
      line-height: 2.8rem;
    }
  }

  &.heading2-compact {
    font-size: 4.75rem;
    line-height: 4.275rem;

    @include desktop {
      font-size: 8rem;
      line-height: 7.2rem;
    }
  }

  &.heading3-regular {
    font-size: 3.5rem;
    line-height: 3.15rem;

    @include desktop {
      font-size: 6rem;
      line-height: 5.4rem;
    }
  }

  &.heading3-sub {
    font-size: 1rem;
    line-height: 1.4rem;
    color: var(--clr-dark-gray-gray-muted);
    font-weight: 700;

    @include desktop {
      font-size: 1.75rem;
      line-height: 2.1875rem;
    }
  }

  &.heading3-regular-alt {
    font-weight: $font-weight-bold;
    font-size: 1.375rem;
    line-height: 1.71875rem;
    font-family: var(--inter-font);
    text-transform: none;

    @include desktop {
      font-size: 2.25rem;
      line-height: 3.125rem;
    }
  }

  &.paragraph-small {
    font-size: 0.875rem;
    line-height: 1.575rem;

    @include desktop {
      font-size: 1.25rem;
      line-height: 2.25rem;
    }
  }

  &.paragraph-reg {
    font-size: 1rem;
    line-height: 1.8rem;

    @include desktop {
      font-size: 1.5rem;
      line-height: 2.7rem;
    }
  }

  &.label-small {
    font-weight: $font-weight-bold;
    font-size: 0.875rem;
    line-height: 1.3125rem;

    @include desktop {
      font-size: 1.125rem;
      line-height: 1.6875rem;
    }
  }

  &.label-reg {
    font-weight: $font-weight-bold;
    font-size: 1rem;

    @include desktop {
      font-size: 1.5rem;
      line-height: 2.25rem;
    }
  }

  &.label-compact {
    font-weight: $font-weight-medium;
    font-size: 1rem;
    line-height: 1.5;

    @include desktop {
      font-size: 1.5rem;
    }
  }

  &.blockquote {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 2.125rem;

    @include desktop {
      font-size: 2rem;
      line-height: 3.4rem;
    }
  }

  &.list-item-heading,
  &.list-item-subheading {
    font-size: 1rem;
    font-weight: $font-weight-bold;
    line-height: 1.5;

    @include desktop {
      font-size: 1.5rem;
    }
  }
}
