@use 'styles/breakpoints' as *;

$border-color: var(--clr-dark-gray-faded);

.listItem {
  display: flex;
  gap: 1.5rem;
  font-weight: 700;
  align-items: center;
  width: 100%;
  position: relative;

  @include desktop {
    gap: 2rem;
  }

  padding: 1.5rem 0;

  @include desktop {
    padding: 2.5rem 0;
  }

  &.border {
    &::before,
    &::after {
      content: '';
      position: absolute;
      height: 0.125rem;
      left: 0;
      bottom: 0;
      width: 100%;
      transform-origin: left bottom;

      @include desktop {
        height: 0.25rem;
      }
    }

    &::before {
      background-color: var(--clr-dark-gray-faded);
    }
  }

  &.clickable {
    cursor: pointer;
  }

  .leadingAddon {
    color: var(--clr-primary);
    align-self: flex-start;
  }

  .trailingAddon {
    color: var(--clr-black);
    align-self: flex-start;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    width: 100%;
    align-items: flex-start;
  }
}
